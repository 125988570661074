import React from "react";

function FooterCopy() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-center p-6 flex flex-col items-center">
     
      <p className="text-gray-500">Copyright © {currentYear}, Todos los derechos reservados Inteligen-t</p>
    </div>
  );
}

export default FooterCopy;
