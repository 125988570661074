import Breadcum from "../../../../components/breadcum/breadcum";
import ContactForm from "../../../../components/contact/ContactForm";

function Contact() {
  return (
    <section id="contacto">
      <div className="mx-auto max-w-7xl px-6 lg:px-8  mb-24">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-up" data-aos-duration="800" className="hidden md:flex justify-center">
            <img
              src="/img/landing/contact.webp"
              className="rounded-lg shadow-lg w-full md:w-[90%] lg:w-[85%] h-auto md:h-[450px] lg:h-[550px] object-cover"
              alt="Contacto"
            />
          </div>

          <div data-aos="fade-up" data-aos-duration="800">
            <Breadcum title={"Contáctanos"} />
            <h2 className="text-4xl font-bold mt-2 mb-4 text-gray-800 leading-tight">
              Hablemos sobre el futuro digital de tu negocio
            </h2>
            <p className="text-gray-700 mb-4">
              En <span className="text-green-500 font-semibold">Inteligen-t Development</span>, estamos listos para ayudarte a transformar tu negocio con soluciones tecnológicas innovadoras y personalizadas.
              Ya sea que necesites una página web optimizada, un sistema automatizado o una aplicación a medida, nuestro equipo está preparado para brindarte la mejor solución.
            </p>
            <p className="text-gray-700 mb-4">
              Contáctanos hoy mismo para agendar una consulta gratuita y descubrir cómo podemos impulsar tu crecimiento digital. Escríbenos por correo, envíanos un mensaje por WhatsApp o completa el formulario y nos pondremos en contacto contigo lo antes posible. 🚀
            </p>
            
            <ContactForm
              placeHolder_nombre="Nombre"
              placeHolder_btn="Contáctanos"
              placeHolder_correo="Correo"
              placeHolder_mensaje="Mensaje"
              placeHolder_telefono="Teléfono"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
