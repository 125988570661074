import { useState, useEffect } from "react";
import Index from "./pages/index";

function Spinner({ isLoading }) {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-white transition-opacity duration-1000 z-50 ${
        isLoading ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="w-12 h-12 border-4 border-green-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Spinner isLoading={loading} />
      {!loading && <Index />}
    </>
  );
}

export default App;
