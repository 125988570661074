import Aurora from "../../../../components/aurora/aurora";
import { MdCheckCircle } from "react-icons/md";

function BannerV2() {
  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      const offset = 150;
      const elementPosition =
        target.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="relative flex items-center justify-center min-h-screen" id="inicio">
      <div className="container mx-auto max-w-screen-xl px-6 lg:px-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center relative">
          {/* Sección de texto con Aurora en fondo solo en móvil */}
          <div className="relative p-5">
            {/* Aurora en fondo solo en móviles */}
            <div className="absolute inset-0 lg:hidden -z-10 opacity-40">
              <Aurora />
            </div>

            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-6xl relative z-10 text-center lg:text-left">
              Desarrollo inteligente para un mundo digital
            </h1>
            <p className="mt-6 text-lg text-gray-600 sm:text-xl relative z-10 text-center md:text-left">
              Potenciamos tu negocio con soluciones digitales a la medida. Desde páginas web optimizadas hasta sistemas de automatización eficientes, llevamos tu empresa al siguiente nivel.
            </p>
            <ul className="mt-6 space-y-3 text-lg text-gray-700 relative z-10 hidden 2xl:block">
              <li className="flex items-center">
                <MdCheckCircle className="text-green-500 mr-2 text-xl" />
                <span>Soluciones a medida para cada necesidad</span>
              </li>
              <li className="flex items-center">
                <MdCheckCircle className="text-green-500 mr-2 text-xl" />
                <span>Especialistas en desarrollo con tecnologías modernas</span>
              </li>
              <li className="flex items-center">
                <MdCheckCircle className="text-green-500 mr-2 text-xl" />
                <span>Consulta gratuita para conocer tu proyecto</span>
              </li>
            </ul>
            <p className="mt-6 text-gray-600 text-lg italic relative z-10 text-center lg:text-left">
              "Innovación y desarrollo sin límites"
            </p>
            <div className="mt-8 flex justify-center lg:justify-start relative z-10">
              <a
                onClick={(e) => handleSmoothScroll(e, "#contacto")}

                className="rounded-lg bg-indigo-600 px-6 py-3 text-lg font-semibold text-white shadow-lg transition-transform transform hover:scale-105 hover:bg-indigo-500  text-center md:text-left"
              >
                Agenda una consulta gratuita
              </a>
            </div>
          </div>

          {/* Sección visual con aurora en pantallas grandes */}
          <div className="relative hidden lg:flex items-center justify-center h-full">
            <Aurora />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerV2;
