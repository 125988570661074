import { IoMailOutline, IoLogoWhatsapp  ,IoLogoFacebook,IoLogoLinkedin   } from "react-icons/io5";

function Footer() {
  return (
    <footer className="bg-neutral-900 text-white py-10 pattern">
      <div className="max-w-7xl mx-auto px-6 grid md:grid-cols-3 gap-8">
        <div>
          <div className="mb-4">
            <img src="/img/logo/inteligen-t_white.svg" alt="Inteligen-t" className="h-14 w-auto " />
          </div>
          <p className="text-gray-400 font-i">
          Innovamos con tecnología inteligente para que tu negocio crezca y destaque en el mundo digital.
          </p>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Contacto</h4>

          <div className="flex items-center mb-3">
            <IoMailOutline className="text-xl text-gray-400" />
            <p className="ml-3 text-gray-300">contacto@inteligen-t.com</p>
          </div>
          <div className="flex items-center mb-3">
            <IoLogoWhatsapp   className="text-xl text-gray-400" />
            <p className="ml-3 text-gray-300">
            +52 1 631 357 4721
            </p>
          </div>
          <div className="flex items-center mb-3">
            <a href="https://www.facebook.com/inteligentservices/" className="flex items-center">
            <IoLogoFacebook className="text-xl text-gray-400" />
            <p className="ml-3 text-gray-300">
            Inteligen-T 
            </p>
            </a>
          </div>
          <div className="flex items-center mb-3">
            <a href="https://www.linkedin.com/company/inteligen-t/" className="flex items-center">
            <IoLogoLinkedin className="text-xl text-gray-400" />
            <p className="ml-3 text-gray-300">
            Inteligen-T 
            </p>
            </a>
          </div>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Secciones</h4>
          <nav className="flex flex-col space-y-2">
            <a href="#home" className="text-gray-300 hover:text-white">Inicio</a>
            <a href="#about" className="text-gray-300 hover:text-white">Sobre nosotros</a>
            <a href="#services" className="text-gray-300 hover:text-white">Nuestros servicios</a>
            <a href="#contactus" className="text-gray-300 hover:text-white">Contáctanos</a>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
