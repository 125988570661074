import BannerV2 from "../../pages/index/sections/banner/bannerv2";
import Navbar from "../../components/navbar/navbar";
import WorkUsV2 from "./sections/work_us/work_usv2";
import ContactUs from "./sections/contact/contact";
import LogosCarrousel from "../../components/logos_carrousel/logos_carrousel";
import AboutUs from "./sections/about_us/about_us";
import Services from "./sections/services/services";
import ValueProposition from "./sections/value/value";
import Footer from "./sections/footer/footer";
import FooterCopy from "./sections/footer_copy/footer_copy";
function Index() {
  return (
    <>
      <Navbar />
      <BannerV2 />
      <AboutUs />
      <Services/>
      <ValueProposition/>
      <LogosCarrousel />
      <ContactUs />
      <Footer/>
      <FooterCopy/>
    </>
  );
}

export default Index;
