import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm({
  placeHolder_nombre,
  placeHolder_telefono,
  placeHolder_correo,
  placeHolder_mensaje,
  placeHolder_btn,
}) {
  const [showModal, setShowModal] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!toSend.from_name.trim())
      newErrors.from_name = "Por favor, escriba su nombre.";
    if (!toSend.message.trim())
      newErrors.message = "Por favor ingrese un mensaje.";
    if (!validateEmail(toSend.reply_to))
      newErrors.reply_to =
        "Por favor, introduce una dirección de correo electrónico válida.";
    if (toSend.phone.trim().length !== 10 || isNaN(toSend.phone.trim()))
      newErrors.phone = "Ingrese un número de teléfono válido (10 dígitos).";

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    emailjs
      .send("service_pirmik6", "Cliente", toSend, "ZuxwADX_OxMWvVef6")
      .then(() => {
        emailjs
          .send(
            "service_pirmik6",
            "Usuario",
            { ...toSend, to_email: "contacto@inteligen-t.com" },
            "ZuxwADX_OxMWvVef6"
          )
          .then(() => {
            handleOpenModal();
            setToSend({ from_name: "", phone: "", reply_to: "", message: "" });
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  return (
    <>
      <form onSubmit={handleSubmit} className="">
        <div className="mb-4">
          <input
            type="text"
            placeholder={placeHolder_nombre}
            name="from_name"
            value={toSend.from_name}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
          <p className="text-red-500 text-sm">{errors.from_name}</p>
        </div>
        <div className="mb-4">
          <input
            type="number"
            placeholder={placeHolder_telefono}
            maxLength={10}
            name="phone"
            value={toSend.phone}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
          <p className="text-red-500 text-sm">{errors.phone}</p>
        </div>
        <div className="mb-4">
          <input
            type="email"
            placeholder={placeHolder_correo}
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
          <p className="text-red-500 text-sm">{errors.reply_to}</p>
        </div>
        <div className="mb-4">
          <textarea
            placeholder={placeHolder_mensaje}
            name="message"
            value={toSend.message}
            onChange={handleChange}
            className="w-full p-2 border rounded-md h-32"
          ></textarea>
          <p className="text-red-500 text-sm">{errors.message}</p>
        </div>
        <div className="mb-4 flex justify-center">
          <button
            type="submit"
            className="rounded-lg bg-indigo-600 px-6 py-3 text-md font-semibold text-white shadow-lg transition-transform transform hover:scale-105 hover:bg-indigo-500 w-full"
          >
            {placeHolder_btn}
          </button>
        </div>
      </form>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold">
              Correo enviado correctamente
            </h2>
            <p className="mt-2">
              ¡Muchas gracias por tu preferencia! Alguien de nuestro equipo se
              comunicará con usted en breve.
            </p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-green-600 text-white px-4 py-2 rounded-md"
            >
              Entiendo
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactForm;
