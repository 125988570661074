import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { MdOutlineWhatsapp } from "react-icons/md";
export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const navLinks = [
    { text: "Inicio", href: "#inicio" },
    { text: "Sobre nosotros", href: "#sobre-nosotros" },
    { text: "Nuestros servicios", href: "#servicios" },
    { text: "Contáctanos", href: "#contacto" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      const offset = 150;
      const elementPosition =
        target.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setMobileMenuOpen(false);
  };

  return (
    <motion.header
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 left-0 w-full z-40"
    >
      <motion.div
        animate={
          scrolled
            ? {
                backgroundColor: "white",
                y: 0,
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }
            : { backgroundColor: "transparent", y: -10, boxShadow: "none" }
        }
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="w-full p-6"
      >
        <div className="flex justify-between items-center max-w-7xl mx-auto">
          <div className="flex items-center">
            <img src="./img/logo/inteligen-t.svg" alt="Logo inteligen-t svg" className="h-10" />
          </div>

          <nav className="hidden lg:flex gap-8">
            {navLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                onClick={(e) => handleSmoothScroll(e, link.href)}
                className="font-semibold text-lg text-gray-500 hover:text-gray-900 transition-all cursor-pointer"
              >
                {link.text}
              </a>
            ))}
          </nav>
          <div
            className={`lg:flex items-center gap-6 transition-all hidden ${
              scrolled ? "!hidden" : "hidden"
            }`}
          >
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-100 rounded-full">
                <a
                  href="https://wa.me/526313574721"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer transition-transform ransition-transform transform hover:scale-105"
                >
                  <MdOutlineWhatsapp className="h-9 w-9 text-[#25D366] "/>
                </a>
              </div>
            </div>
            <a
              href="#"
              onClick={(e) => handleSmoothScroll(e, '#contacto')}
              className="rounded-lg bg-indigo-600 px-6 py-3 text-md font-semibold text-white shadow-lg transition-transform transform hover:scale-105 hover:bg-indigo-500"
            >
              ¡Trabajemos juntos!
            </a>
          </div>

          <button
            className="lg:hidden p-2"
            onClick={() => setMobileMenuOpen(true)}
          >
            <Bars3Icon className="h-8 w-8 text-gray-700" />
          </button>
        </div>
      </motion.div>

      <AnimatePresence>
        {mobileMenuOpen && (
          <Dialog
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            className="fixed inset-0 z-50 lg:hidden"
          >
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="fixed inset-0 bg-white w-3/4 h-full shadow-lg"
            >
              <DialogPanel className="h-full p-6">
                <div className="flex justify-between items-center mb-6">
                  <img
                    src="./img/logo/inteligen-t.svg"
                    alt="Logo"
                    className="h-10"
                  />
                  <button onClick={() => setMobileMenuOpen(false)}>
                    <XMarkIcon className="h-8 w-8 text-gray-700" />
                  </button>
                </div>

                {/* Mobile Links */}
                <nav className="flex flex-col gap-4">
                  {navLinks.map((link, index) => (
                    <a
                      key={index}
                      href={link.href}
                      onClick={(e) => handleSmoothScroll(e, link.href)}
                      className="text-gray-700 text-lg font-medium hover:text-blue-600 transition-all"
                    >
                      {link.text}
                    </a>
                  ))}
                </nav>
              </DialogPanel>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </motion.header>
  );
}
