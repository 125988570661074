import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useEffect } from "react";

export default function LogosCarrousel() {
  const logos = [
    { src: "img/company_logos/aries.png", link: "https://www.ariesgeneralbuilders.com" },
    { src: "img/company_logos/r3_ingenieria.svg", link: "https://www.r3ingenieria.com/" },
    { src: "img/company_logos/r3_rentas.png", link: "https://www.r3rentas.com" },

    { src: "img/company_logos/corella.png", link: "https://construccionescorella.com/" },
  ];

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: { perView: 4, spacing: 5 },
    breakpoints: {
      "(max-width: 1024px)": { slides: { perView: 3, spacing: 10 } },
      "(max-width: 768px)": { slides: { perView: 2, spacing: 8 } },
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      instanceRef.current?.next();
    }, 3000);

    return () => clearInterval(interval);
  }, [instanceRef]);

  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-gray-900">
          Empresas que confían en <span className="text-yellow-500">Inteligen-t</span>
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Colaboramos con empresas innovadoras para desarrollar soluciones tecnológicas a medida.
        </p>
        <div ref={sliderRef} className="keen-slider mt-10">
          {logos.map((logo, index) => (
            <div key={index} className="keen-slider__slide flex justify-center">
              <a href={logo.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={logo.src}
                  alt={`Company ${index + 1}`}
                  className="max-h-12 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
