import Breadcum from "../../../../components/breadcum/breadcum";
import CardService from "./components/card_service";
import { FaLaptopCode, FaShoppingCart, FaCogs, FaMobileAlt } from "react-icons/fa"; // Íconos para cada servicio

export default function Services() {
  const services = [
    {
      title: "Landing Pages que convierten",
      text: "Diseñamos landing pages estratégicas, optimizadas para generar clientes potenciales. Creamos experiencias atractivas y funcionales para impulsar tu negocio.",
      list: [
        "Diseño moderno y adaptable",
        "Optimización SEO y velocidad de carga",
        "Integraciones con CRM y herramientas de marketing",
      ],
      icon: FaLaptopCode, // Icono para este servicio
    },
    {
      title: "E-commerce personalizado",
      text: "Transformamos tu idea en una tienda en línea eficiente y rentable. Nos adaptamos a tu negocio y te ofrecemos una solución escalable y segura.",
      list: [
        "Desarrollo a medida y plataformas personalizadas",
        "Integración con pasarelas de pago y envíos",
        "Experiencia de usuario optimizada para más ventas",
      ],
      icon: FaShoppingCart, // Icono para este servicio
    },
    {
      title: "CRM y Automatización",
      text: "Simplificamos la gestión de tu negocio con herramientas inteligentes. Automatiza procesos, mejora la organización y optimiza tus reportes con nuestro desarrollo de CRM.",
      list: [
        "Gestión eficiente de clientes y ventas",
        "Reportes detallados para la toma de decisiones",
        "Automatización de tareas para mayor productividad",
      ],
      icon: FaCogs, // Icono para este servicio
    },
    {
      title: "Desarrollo de Apps a Medida",
      text: "Creamos aplicaciones personalizadas para mejorar la eficiencia y escalabilidad de tu negocio. Desde soluciones móviles hasta plataformas integradas, diseñamos herramientas tecnológicas adaptadas a tus necesidades.",
      list: [
        "Desarrollo de apps nativas y multiplataforma",
        "Integración con sistemas empresariales y APIs",
        "Interfaces intuitivas y experiencia de usuario optimizada",
      ],
      icon: FaMobileAlt, // Icono para este servicio
    },
  ];

  return (
    <section id="servicios" className="mx-auto max-w-7xl px-6 lg:px-8 mt-32">
      <div>
        <Breadcum title={"Nuestros servicios"}/>
        <h2 className="text-4xl font-bold mt-2 mb-5 ">
          Soluciones Digitales para Impulsar tu Negocio
        </h2>
      </div>

      <p className="text-gray-600 text-lg">
        En <strong>Inteligen-t Development</strong> , ofrecemos servicios de desarrollo web y
        software personalizados para ayudar a empresas a optimizar sus
        operaciones, mejorar su presencia en línea y generar más oportunidades
        de negocio. Nos especializamos en crear plataformas innovadoras y
        funcionales que se adaptan a las necesidades de cada cliente.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mt-10">
        {services.map((service, index) => (
          <CardService
            key={index}
            title={service.title}
            text={service.text}
            list={service.list}
            icon={service.icon}
          />
        ))}
      </div>
    </section>
  );
}
