import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import {
  MdLightbulb,
  MdCheckCircle,
  MdEco,
  MdSyncAlt,
  MdGroups,
} from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import Breadcum from "../../../../components/breadcum/breadcum";
import { useEffect, useState } from "react";

export default function AboutUs() {
  const links = [
    {
      name: "Innovación",
      desc: "Adoptar las últimas tecnologías para ofrecer soluciones de alta calidad.",
      icon: <MdLightbulb size={28} className="text-green-500" />,
    },
    {
      name: "Compromiso",
      desc: "Cumplir con los tiempos y necesidades específicas de cada cliente.",
      icon: <MdCheckCircle size={28} className="text-blue-500" />,
    },
    {
      name: "Sostenibilidad",
      desc: "Promover prácticas responsables en el desarrollo de tecnología.",
      icon: <MdEco size={28} className="text-green-400" />,
    },
    {
      name: "Flexibilidad",
      desc: "Adaptarse a las necesidades de empresas de diferentes tamaños.",
      icon: <MdSyncAlt size={28} className="text-yellow-500" />,
    },
    {
      name: "Colaboración",
      desc: "Trabajar en equipo para maximizar la creatividad y eficiencia en cada proyecto.",
      icon: <MdGroups size={28} className="text-purple-500" />,
    },
  ];

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: { perView: 3.2, spacing: 15 }, // 3 slides completos + parte del siguiente
    breakpoints: {
      "(max-width: 1024px)": { slides: { perView: 2.2, spacing: 10 } },
      "(max-width: 768px)": { slides: { perView: 1.2, spacing: 8 } },
    },
  });

  // Auto Slide cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      instanceRef.current?.next();
    }, 3000);

    return () => clearInterval(interval);
  }, [instanceRef]);

  return (
    <section className="mx-auto max-w-7xl px-6 lg:px-8 mb-16" id="sobre-nosotros">
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-5 hidden md:block">
          <img
            className="rounded-lg shadow-lg w-full md:w-[90%] lg:w-[100%] h-auto md:h-[550px] lg:h-[650px] object-cover"
            src="img/landing/about.webp"
            alt="Sobre nosotros"
          />
        </div>
        <div className="col-span-12 md:col-span-7">
          <Breadcum title="Sobre Nosotros" />

          <h2 className="text-4xl font-bold mt-2 mb-4 text-gray-800 leading-tight">
            Sobre Inteligen-t Development
          </h2>
          <p className="text-gray-600 text-lg">
            Somos <span className="text-green-500 font-semibold">Inteligen-t Development</span>, un equipo de expertos en desarrollo web y software especializado en soluciones digitales. Con un enfoque en la innovación y la eficiencia, ayudamos a empresas del sector construcción, arquitectura y más a digitalizar sus operaciones y mejorar su presencia en línea.
          </p>

          <p className="text-gray-500 italic mt-4">
            "Eficiencia y profesionalismo en cada proyecto."
          </p>

          <div className="grid grid-cols-2 gap-4 mt-6">
            <ul className="space-y-3">
              <li className="flex items-center space-x-2">
                <FaCheckCircle className="text-green-500" size={20} />
                <span className="text-gray-700 text-lg">Landing Pages que convierten</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheckCircle className="text-green-500" size={20} />
                <span className="text-gray-700 text-lg">E-commerce personalizado</span>
              </li>
            </ul>
            <ul className="space-y-3">
              <li className="flex items-center space-x-2">
                <FaCheckCircle className="text-green-500" size={20} />
                <span className="text-gray-700 text-lg">CRM y Automatización</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheckCircle className="text-green-500" size={20} />
                <span className="text-gray-700 text-lg">Desarrollo de Apps a Medida</span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="mt-8 text-2xl font-semibold text-gray-800">Nuestros valores</h3>
            <div ref={sliderRef} className="keen-slider relative mt-6">
              {links.map((link, index) => (
                <div
                  key={index}
                  className="keen-slider__slide p-5 bg-white shadow-md rounded-xl border border-gray-200 flex flex-col mb-3 cursor-grab"
                >
                  <div className="flex items-center mb-3">
                    <div className="mr-2">{link.icon}</div>
                    <h5 className="text-lg font-semibold text-gray-800">{link.name}</h5>
                  </div>
                  <p className="text-gray-600 flex-grow">{link.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
