import { FaCheckCircle } from "react-icons/fa"; // Ícono para la lista
import { FaLaptopCode, FaShoppingCart, FaCogs, FaMobileAlt } from "react-icons/fa"; // Íconos para cada servicio

export default function CardService({ title, text, list = [], icon: IconComponent }) {
  return (
    <div className="relative rounded-2xl bg-neutral-900 p-6 border border-neutral-800 shadow-lg hover:shadow-xl transition-all duration-300">
      {IconComponent && (
        <IconComponent className="absolute top-4 right-4 text-gray-600 opacity-20 text-8xl" />
      )}

      <h3 className="font-bold text-white text-2xl mb-3">{title}</h3>
      <p className="text-gray-300 text-lg">{text}</p>
      {list.length > 0 && (
        <ul className="mt-4 space-y-2">
          {list.map((item, index) => (
            <li key={index} className="flex items-center text-gray-300">
              <FaCheckCircle className="text-green-400 mr-2" /> {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
