import { FaCheckCircle } from "react-icons/fa";

export default function ValueProposition() {
  const differentiators = [
    {
      text: "Adaptabilidad: Personalizamos cada proyecto según las necesidades específicas del cliente.",
    },
    {
      text: "Soporte post-proyecto: Mantenimiento y actualizaciones continuas para garantizar el éxito a largo plazo.",
    },
    {
      text: "Eficiencia: Capacidad para escalar proyectos mediante subcontratación estratégica y liderazgo técnico interno.",
    },
    {
      text: "Sostenibilidad: Compromiso con prácticas responsables en el uso de recursos tecnológicos.",
    },
  ];

  return (
    <section className="bg-neutral-900 text-white py-16 px-6 lg:px-20 shadow-lg mt-20 pattern">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mt-2">¿Qué nos diferencia?</h2>
        <p className="text-gray-300 text-lg mt-4">
          "En <span className="text-green-400 font-semibold">Inteligen-t Development</span>, ofrecemos soluciones tecnológicas personalizadas que combinan innovación, accesibilidad y soporte continuo."
        </p>
      </div>

      <div className="mt-8 max-w-3xl mx-auto">
        <ul className="space-y-4">
          {differentiators.map((item, index) => (
            <li key={index} className="flex items-start text-gray-300">
              <FaCheckCircle className="text-green-400 mr-3 text-xl flex-shrink-0" />
              <span className="text-lg">{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
